










































import { ExpandableDownloadableItemContent } from '@/models/frontendOnly/ExpandableDownloadableItem';
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DownloadContents extends Vue {
  @Prop()
  contents!: ExpandableDownloadableItemContent[] | null;

  @Prop()
  err!: boolean;

  @Prop()
  title!: string;

  @Prop({default: false, type: Boolean})
  hideTitle!: boolean;

  getIcon(kind: string) { 
    if (kind === 'txt'){ 
      return 'fa-light fa-file-alt';
    }else if (kind === 'pdf') { 
      return 'fa-light fa-file-pdf';
    }else if (kind === 'csv') { 
      return 'fa-light fa-file-csv';
    }else { 
      return 'fa-light fa-file';
    }
  }

}
